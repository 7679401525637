import { SetState } from 'zustand';

import {
  DynamicPackageOrderedFilter,
  DynamicPackagePinnedResult,
  DynamicPackagePopularFilterGroup,
  DynamicPackageResultOrPromotionalCard,
} from '@AuroraTypes';
import { createStore } from '@Core/createStore';
import { ProductType } from '@Core/tracking/types';
import { removeFunctionMembers } from '@Core/utils';

export interface SearchResultsStore {
  fetching: boolean;
  mapSearchFetching: boolean;
  paginating: boolean;
  results: DynamicPackageResultOrPromotionalCard[];
  mapSearchResults: DynamicPackageResultOrPromotionalCard[];
  pinnedResults: DynamicPackagePinnedResult[];
  totalResults: number;
  nextPageOffset: number;
  orderedFilters: DynamicPackageOrderedFilter[];
  popularFilters: DynamicPackagePopularFilterGroup[];
  shortRef?: string;
  offerId?: string;
  missedIt?: boolean;
  productType?: ProductType;
  setResults: (results: DynamicPackageResultOrPromotionalCard[]) => void;
  setInitialValues: (overrides?: any) => void;
}

const reducer =
  (set: SetState<SearchResultsStore>, fieldName: keyof SearchResultsStore) => (value: unknown) =>
    set((state: SearchResultsStore) => ({ ...state, [fieldName]: value }));

export const createSearchResultsStore = (initialValues?: Partial<SearchResultsStore>) =>
  createStore<SearchResultsStore>(
    (set) => ({
      fetching: false,
      mapSearchFetching: false,
      paginating: false,
      results: [],
      mapSearchResults: [],
      pinnedResults: [],
      totalResults: 0,
      nextPageOffset: 0,
      orderedFilters: [],
      popularFilters: [],
      ...initialValues,

      setResults: reducer(set, 'results'),
      setInitialValues: (overrides: Partial<SearchResultsStore>) => {
        const extended = createSearchResultsStore(overrides).getState();
        set(removeFunctionMembers(extended) as SearchResultsStore);
      },
    }),
    'SearchResultsStore',
  );
