import { SetState } from 'zustand';

import {
  Accommodation,
  AirportWithAvailability,
  AirportWithCodes,
  AlternativeFlightFilterStepper,
  CancellationPolicies,
  DestinationDetail,
  MaxFlightStopsPreferences,
  NightWithAvailability,
  Party,
  SortOption,
  TimeBucketsOptions,
} from '@AuroraTypes';
import { DATE_FLEXIBILITY_VALUES } from '@Constants/Flexibility';
import { createStore } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';
import { scrollToAlternativeFlights } from '@Pages/panda/AlternativeFlights/scrollToAlternativeFlights';

export interface AvailabilityOption {
  id: string;
  name: string;
  available?: boolean;
}

export interface AirportAvailabilityOption
  extends AvailabilityOption,
    Pick<AirportWithCodes, 'codes' | 'location'> {}

export type MonthPrices = (number | null)[];

export interface YearMonthPrices {
  [yearMonth: string]: MonthPrices;
}

export interface SearchAvailabilityStore {
  status: 'ok' | 'accommodation-not-found' | 'client-error';
  errorMessage: string;
  destinations: DestinationDetail[];
  boardBasis: AvailabilityOption[];
  calendar: YearMonthPrices;
  hotelOnlyRooms: Party[];
  roomsSelection: Partial<Record<string, string>>;
  outboundTimeBuckets?: TimeBucketsOptions;
  inboundTimeBuckets?: TimeBucketsOptions;
  cancellationPolicies?: CancellationPolicies;
  includedCheckedBags?: AlternativeFlightFilterStepper;
  maxFlightStops?: MaxFlightStopsPreferences;
  departureAirports: AirportWithAvailability[];
  isAccommodationFetching: boolean;
  isAvailabilityFetching: boolean;
  isHotelOnlyRoomsFetching: boolean;
  isAlternativeFlightsShown: boolean;
  flexibility: number[];
  nights: NightWithAvailability[];
  sort: SortOption[];
  currentAccommodation?: Accommodation;
  searchId?: string;

  setBoardBasis: (options: AvailabilityOption[]) => void;
  setCalendar: (calendar: YearMonthPrices) => void;
  setDepartureAirports: (airports: AirportWithAvailability[]) => void;
  setFlexibility: (options: number[]) => void;
  setNights: (options: NightWithAvailability[]) => void;
  setSearchId: (searchId: string) => void;
  setRoomSelection: (selection: Record<string, string>) => void;
  handleShowAlternativeFlights: () => void;
  setInitialValues: (overrides?: Partial<SearchAvailabilityStore>) => void;

  hasNightsSelection: () => boolean;
  getIsFetching: () => boolean;
}

const reducer =
  (set: SetState<SearchAvailabilityStore>, fieldName: keyof SearchAvailabilityStore) =>
  (value: unknown) =>
    set((state: SearchAvailabilityStore) => ({ ...state, [fieldName]: value }));

export const createSearchAvailabilityStore = (initialValues?: Partial<SearchAvailabilityStore>) =>
  createStore<SearchAvailabilityStore>(
    (set, get) => ({
      status: 'ok',
      errorMessage: '',
      destinations: [],
      boardBasis: [],
      calendar: {},
      hotelOnlyRooms: [],
      roomsSelection: {},
      cancellationPolicies: undefined,
      includedCheckedBags: {
        max: 0,
        min: 0,
      },
      maxFlightStops: undefined,
      outboundTimeBuckets: undefined,
      inboundTimeBuckets: undefined,
      departureAirports: [],
      isAccommodationFetching: false,
      isAvailabilityFetching: false,
      isHotelOnlyRoomsFetching: false,
      isAlternativeFlightsShown: false,
      nights: [],
      flexibility: [...DATE_FLEXIBILITY_VALUES],
      sort: [],
      currentAccommodation: undefined,
      searchId: undefined,
      ...initialValues,

      setBoardBasis: reducer(set, 'boardBasis'),
      setCalendar: reducer(set, 'calendar'),
      setDepartureAirports: reducer(set, 'departureAirports'),
      setFlexibility: reducer(set, 'flexibility'),
      setNights: reducer(set, 'nights'),
      setSearchId: reducer(set, 'searchId'),
      setRoomSelection: (selection: Record<string, string>) => {
        const { roomsSelection } = get();
        reducer(set, 'roomsSelection')({ ...roomsSelection, ...selection });
      },
      handleShowAlternativeFlights: () => {
        reducer(set, 'isAlternativeFlightsShown')(true);
        scrollToAlternativeFlights();
      },
      setInitialValues: (overrides) => {
        const extended = createSearchAvailabilityStore(overrides).getState();
        set(removeFunctionMembers(extended) as SearchAvailabilityStore);
      },

      getIsFetching: () => {
        const { isAccommodationFetching, isAvailabilityFetching } = get();

        return isAccommodationFetching && isAvailabilityFetching;
      },
      hasNightsSelection: () => {
        const { nights } = get();

        return !!nights.length;
      },
    }),
    'SearchAvailabilityStore',
  );
