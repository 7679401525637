import { isIOS } from '@Client/utils/isIOS';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';

export const alternateFlightsId = 'alternative-flights';
export const alternateFlightsModalId = 'alternate-flights-modal';

export const scrollToAlternativeFlights = () => {
  // Flights selector modal
  const modalBody = document.getElementById(alternateFlightsModalId)?.parentElement;

  if (modalBody) {
    modalBody.scrollTop = 0;

    return;
  }

  // Alternative flights section on P&A page
  const altFlightsDOM = document.getElementById(alternateFlightsId);
  if (altFlightsDOM) {
    // Set timeout is required to ensure scrolling works properly
    setTimeout(
      () =>
        altFlightsDOM.scrollIntoView({
          // Disable smooth scroll for iOS devices to fix alternative flights modal cut off issue
          behavior: isIOS() ? 'instant' : getScrollBehavior(),
          block: 'start',
        }),
      100,
    );
  }
};
