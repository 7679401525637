import { sendEvent } from '../sendEvent';
import { TrackingEvent } from '../types';

type FavouritesTrackingAction =
  | 'create-new-list'
  | 'change-list'
  | 'done-creating-new-list'
  | 'done-changing-list';

export const useFavouritesTracking = () => (action: FavouritesTrackingAction) => {
  sendEvent({
    event: TrackingEvent.favourites,
    action,
  });
};
