import React, { Fragment, useCallback, useEffect } from 'react';
import { useClient } from 'urql';

import getFavouritesLists from './graphql/getFavouritesLists.gql';
import { readOrFetchQuery } from '@Core/readOrFetchQuery';
import { useStoreContext } from '@Stores/StoreContext';

export const FavouritesStoreUpdater: React.FC<React.PropsWithChildren> = ({ children }) => {
  const urqlClient = useClient();
  const { favourites } = useStoreContext();

  const fetchAndUpdateResults = useCallback(() => {
    return readOrFetchQuery(urqlClient, getFavouritesLists, {}, ({ data, error }) => {
      const favouritesLists = data?.User.favouritesLists.items;

      if (error || !favouritesLists) {
        // TODO how to handle error when getting favourites?
        favourites.setState({ fetched: true });

        return;
      }

      const transformedUserFavouritesLists = favouritesLists.map((list) => ({
        id: list.id,
        title: list.name,
        search: list.searchQuery,
        items: list.masterIds,
        updatedAt: 1, // Remove this once we no longer use localStorage favourites
      }));

      favourites.setState({
        fetched: true,
        favouritesLists: transformedUserFavouritesLists,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 1. Initialise favourites store from backend
    fetchAndUpdateResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
};
