/**
 * Like `URLSearchParams`, but encodes using the same logic as
 * `encodeURIComponent` instead of `application/x-www-form-urlencoded`.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#:~:text=For%20application/x%2Dwww%2Dform%2Durlencoded%2C%20spaces%20are%20to%20be%20replaced%20by%20%2B%2C%20so%20one%20may%20wish%20to%20follow%20a%20encodeURIComponent()%20replacement%20with%20an%20additional%20replacement%20of%20%2520%20with%20%2B.
 */
// eslint-disable-next-line no-restricted-globals
export class URLQueryParams extends URLSearchParams {
  // eslint-disable-next-line no-restricted-globals
  constructor(init?: string[][] | Record<string, string> | string | URLSearchParams) {
    super(init);
    Object.setPrototypeOf(this, URLQueryParams.prototype);
  }

  toString(): string {
    return super.toString().replace(/\+/g, '%20');
  }

  toObject(): Record<string, string> {
    return Object.fromEntries(this);
  }
}
