import dayjs from 'dayjs';
import { SetState } from 'zustand';

import {
  defaultFilterValues,
  defaultSearchSelection,
  defaultSortValue,
} from './defaultSearchSelection';
import { deselectSearchSelection } from './deselectSearchSelection';
import { getUrlFromSearchSelection } from './getUrlFromSearchSelection';
import type {
  ID,
  SearchSelectionStore,
  SearchSelectionStoreInitialValue,
  SrpViewMode,
} from './types';
import type {
  PageType,
  RoomConfiguration,
  RoomConfigurationInput,
  SearchSelection,
  SortOption,
} from '@AuroraTypes';
import { isMonthFlexibility } from '@Components/SearchForm/isMonthFlexibility';
import { DEFAULT_FLEXIBILITY } from '@Constants/Flexibility';
import { createStore, Store } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';
import { ISO_DATE_FORMAT, utcDate } from '@Dates/dates';

export type { SearchSelectionStore, SearchSelectionStoreInitialValue, SrpViewMode };

export const isFamily = (rooms: RoomConfigurationInput[]) =>
  rooms.some((room) => !!room.childAges.length);

const stateToUrlMapping: Partial<Record<PageType, (keyof SearchSelectionStore)[]>> = {
  panda: [
    'masterId',
    'departureAirports',
    'boardBasis',
    'nights',
    'rooms',
    'date',
    'cancellationPolicy',
    'maxFlightStops',
    'includedCheckedBags',
    'outboundTimeBuckets',
    'inboundTimeBuckets',
  ],

  srp: [
    'destinationIds',
    'pinnedMasterIds',
    'departureAirports',
    'nights',
    'rooms',
    'date',
    'flexibility',
    'sort',
    'filters',
  ],

  landingseo: ['destinationIds', 'departureAirports', 'nights', 'rooms', 'date', 'flexibility'],

  favourites: ['departureAirports', 'date', 'flexibility', 'nights', 'rooms'],
};

export const stateToCookieFields: (keyof SearchSelectionStore)[] = [
  'destinationIds',
  'departureAirports',
  'nights',
  'date',
  'flexibility',
  'rooms',
  'cancellationPolicy',
  'includedCheckedBags',
  'maxFlightStops',
  'outboundTimeBuckets',
  'inboundTimeBuckets',
  'filters',
  'sort',
];

const reducer =
  <T>(set: SetState<SearchSelectionStore>, fieldName: keyof SearchSelectionStore) =>
  (value: T) =>
    set((state: SearchSelectionStore) => ({ ...state, [fieldName]: value }));

export const createSearchSelectionStore = (
  initialValues: SearchSelectionStoreInitialValue = {},
): Store<SearchSelectionStore> =>
  createStore<SearchSelectionStore>(
    (set, get) => ({
      masterIds: [],
      pinnedMasterIds: [],
      boardBasis: [],
      cancellationPolicy: [],
      maxFlightStops: [],
      outboundTimeBuckets: [],
      inboundTimeBuckets: [],
      includedCheckedBags: [],
      viewMode: 'list',
      departureAirports: [],
      destinationIds: [],
      flexibility: DEFAULT_FLEXIBILITY,
      nights: 7,
      rooms: [
        {
          adults: 2,
          childAges: [],
        },
      ],
      resultsStartIndex: 0,
      sort: defaultSortValue,
      ...initialValues,
      date: (() => {
        const { date } = initialValues;

        if (dayjs.isDayjs(date)) {
          return date;
        }

        if (typeof date === 'string') {
          return utcDate(date, ISO_DATE_FORMAT);
        }

        return;
      })(),
      filters: {
        ...defaultFilterValues,
        ...(initialValues.filters as SearchSelectionStore['filters']),
      },
      isHotelOnly: initialValues.isHotelOnly || false,

      setHotelOnly: reducer<boolean>(set, 'isHotelOnly'),
      setSort: reducer<SortOption>(set, 'sort'),
      setBoardBasis: reducer<string[]>(set, 'boardBasis'),
      setOutboundTimeBuckets: reducer<string[]>(set, 'outboundTimeBuckets'),
      setInboundTimeBuckets: reducer<string[]>(set, 'inboundTimeBuckets'),
      setCancellationPolicy: reducer<string[]>(set, 'cancellationPolicy'),
      setIncludedCheckedBags: (includedCheckedBags) => {
        set((state) => ({
          ...state,
          includedCheckedBags: includedCheckedBags !== undefined ? [includedCheckedBags] : [],
        }));
      },
      setMaxFlightStops: reducer<number[]>(set, 'maxFlightStops'),
      setViewMode: reducer<SrpViewMode>(set, 'viewMode'),
      setDate: (date, flexibility = DEFAULT_FLEXIBILITY) =>
        set({
          date,
          flexibility,
        }),
      setDateOnly: (date) =>
        set((state) => ({
          ...state,
          date,
          flexibility:
            isMonthFlexibility(state.flexibility) || !state.flexibility
              ? DEFAULT_FLEXIBILITY
              : state.flexibility,
        })),
      setDepartureAirports: reducer<string[]>(set, 'departureAirports'),
      setDestinationIds: reducer<string[]>(set, 'destinationIds'),
      setFlexibility: reducer<number>(set, 'flexibility'),
      setMasterId: reducer<ID>(set, 'masterId'),
      setNights: reducer<number>(set, 'nights'),
      setRooms: reducer<RoomConfiguration[]>(set, 'rooms'),
      setFilterSelection: (overrides) =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            ...overrides,
          },
        })),
      resetFilters: () =>
        set((state) => ({
          ...state,
          filters: {
            ...defaultFilterValues,
          },
        })),
      hasActiveFilters: () => {
        const { filters } = get();

        return Object.values(filters).some((value) =>
          Array.isArray(value) ? !!value.length : !!value,
        );
      },
      setResultsStartIndex: reducer<number>(set, 'resultsStartIndex'),
      clearPinnedMasterIds: () =>
        set(() => ({
          pinnedMasterIds: [],
        })),
      removePinnedMasterId: (id) => {
        set((state) => ({
          pinnedMasterIds: state.pinnedMasterIds.filter((mid) => mid !== id),
        }));
      },
      toUrl: (pageType) => {
        const fields = (stateToUrlMapping[pageType] || []) as string[];
        const state = get();

        return getUrlFromSearchSelection(state, fields);
      },
      toCookie: () => {
        const state = get();

        return getUrlFromSearchSelection(state, stateToCookieFields as string[]);
      },
      isFamilySearch: () => {
        const state = get();

        return isFamily(state.rooms);
      },
      isSpecificDateSelected: () => {
        const state = get();

        return !!state.date && !isMonthFlexibility(state.flexibility);
      },
      isMonthSelected: () => {
        const state = get();

        return !!state.date && isMonthFlexibility(state.flexibility);
      },
      resetSearchSelection: () => {
        set(() => ({
          ...defaultSearchSelection,
        }));
      },
      deselectSearchSelection: (value: SearchSelection) => {
        set((state) =>
          deselectSearchSelection(state, {
            ...value,
            date: value.date ? utcDate(value.date, ISO_DATE_FORMAT) : undefined,
          }),
        );
      },
      extend: (overrides = {}) =>
        createSearchSelectionStore({
          ...get(),
          ...overrides,
        }).getState(),
      setInitialValues: (overrides: SearchSelectionStoreInitialValue = {}) => {
        const extended = createSearchSelectionStore(overrides).getState();
        set(removeFunctionMembers(extended) as SearchSelectionStore);
      },
    }),
    'SearchSelectionStore',
  );
