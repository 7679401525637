/* eslint-disable global-require */
/* eslint-disable import/first */
/* eslint-disable no-underscore-dangle */

// https://github.com/webpack/webpack/issues/443#issuecomment-54113862
/* eslint-disable-next-line no-undef */
__webpack_public_path__ = window.__PUBLIC_PATH__;

let devtoolsExchange;
let stylisPlugins;
let polyfillsLoaded!: Promise<any>;

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
  devtoolsExchange = require('@urql/devtools').devtoolsExchange;
}

// Load polyfills for legacy browsers
if (process.env.LEGACY_BROWSER === 'true') {
  stylisPlugins = [
    require(/* webpackChunkName: "stylis-prefixer" */ 'stylis').prefixer,
    require(/* webpackChunkName: "cssGridPrefixerPlugin" */ '@UX/cssGridPrefixerPlugin')
      .cssGridPrefixerPlugin,
  ];
  polyfillsLoaded = Promise.resolve();

  // Load IntersectionObserver polyfill for modern browsers when it is not available (e.g. in web view)
} else if (typeof window.IntersectionObserver === 'undefined') {
  polyfillsLoaded = import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer');

  // In other cases just nark polyfills as loaded
} else {
  polyfillsLoaded = Promise.resolve();
}

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { loadableReady } from '@loadable/component';
import { setup } from '@loveholidays/preact-perf-metrics/setup';
import {
  priceScopeTokensCookie,
  octopusLastUsedRevisionCookie,
  lvhIdCookie,
  lvhCidCookie,
  lvhChannel,
} from '@loveholidays/skyline-cookies';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import UniversalCookie from 'universal-cookie';
import { cacheExchange, createClient, dedupExchange, fetchExchange, ssrExchange } from 'urql';

import { App } from './App';
import {
  tapErrorExchange,
  retryExchange,
  auroraExtensionsExchange,
  removeTypeNamesExchange,
} from '../exchanges';
import { sendUrqlError } from '@Core/errors/errors';
import { loadSentry, initGlobalErrorHandlers } from '@Core/errors/sentryLoader';
import { fetchWithDataDome } from '@Core/fetchWithDataDome';
import { requestIdleCallback } from '@Core/requestIdleCallback';
import { URLQueryParams } from '@Core/url/URLQueryParams';

const cookies = new UniversalCookie();
const urlQueryParams = new URLQueryParams(window.location.search);
const sanityPreviewIds = urlQueryParams.get('sanity-preview-ids');

const cache = createCache({
  key: 'css',
  ...(process.env.LEGACY_BROWSER === 'true' && {
    stylisPlugins,
  }),
});

initGlobalErrorHandlers();

const { App: AppContext } = window.__CONTEXTS__;
const lhx = urlQueryParams.get('lhx');

if (AppContext.collectPerformanceMetrics) {
  setup();
}

const urqlClient = createClient({
  url: '/graphql?app=sunrise',
  exchanges: [
    removeTypeNamesExchange,
    dedupExchange,
    devtoolsExchange,
    tapErrorExchange({
      onError: sendUrqlError,
    }),
    auroraExtensionsExchange(cookies),
    cacheExchange,
    ssrExchange({
      isClient: true,
      initialState: window.__URQL__,
      includeExtensions: true,
    }),
    retryExchange,
    fetchExchange,
  ].filter(Boolean),
  fetch: fetchWithDataDome,
  fetchOptions: () => ({
    headers: {
      accept: 'application/json',
      'x-consumer': 'sunrise-web',
      'x-site': (AppContext.siteOverride || AppContext.site).siteCode,
      'x-consumer-version': AppContext.version,
      ...(AppContext.siteOverride && { 'x-original-site': AppContext.site.siteCode }),
      ...(AppContext.brandOverride && { 'x-brand-override': AppContext.brandOverride }),
      'x-channel': cookies.get(lvhChannel.name) || '',
      'x-lvhid': cookies.get(lvhIdCookie.name) || '',
      'x-lvh-cid': cookies.get(lvhCidCookie.name) || '',
      'x-ps-tokens': cookies.get(priceScopeTokensCookie.name) || '',
      'x-octopus-last-used-revision': cookies.get(octopusLastUsedRevisionCookie.name) || '',
      ...(lhx && { 'x-feature-flag-overrides': lhx }),
      ...(sanityPreviewIds && { 'x-sanity-preview-ids': sanityPreviewIds }),
      ...(window.__AURORA_MOCK__ && { 'x-mock': '1' }),
    },
  }),
});

polyfillsLoaded.then(() => {
  loadableReady(() =>
    hydrate(
      <CacheProvider value={cache}>
        <BrowserRouter basename={window.__CONTEXTS__.App.site.pathPrefix}>
          <App
            contexts={window.__CONTEXTS__}
            cookies={cookies}
            urqlClient={urqlClient}
          />
        </BrowserRouter>
      </CacheProvider>,
      document.getElementById('root') as HTMLElement,
      () => {
        window.hydrationFinished = true;
        requestIdleCallback(() => loadSentry(), { timeout: 5000 });
      },
    ),
  );
});

if (module.hot) {
  module.hot.accept();
}
