import { ThemeProvider } from '@emotion/react';
import { DesignSystemProvider, GlobalStyles } from '@loveholidays/design-system';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import UniversalCookie from 'universal-cookie';

import { AppContext } from './contexts';
import { UserPreferencesContextProvider } from './UserPreferencesContext/UserPreferencesContext';
import { PageLayoutContextProvider } from '@Contexts/PageLayoutContext';
import { RouteContextProvider } from '@Contexts/RouteContext';
import { PricingContextProvider } from '@Core/prices/PricingContext';
import { sendEvent } from '@Core/tracking/sendEvent';
import { type TrackingEvent } from '@Core/tracking/types';
import { Contexts } from '@Server/handlers/handle-contexts/types';
import { FeatureFlagStoreProvider } from '@Stores/FeatureFlag/FeatureFlagStore';

interface ContextsProviderProps {
  contexts: Contexts;
  cookies: UniversalCookie;
}

export const ContextsProvider: React.FC<React.PropsWithChildren<ContextsProviderProps>> = ({
  contexts,
  cookies,
  children,
}) => {
  const history = useHistory();
  const sendEventDs: Parameters<typeof DesignSystemProvider>[0]['sendEvent'] = useCallback(
    ({ event, eventCallback, payload }) => {
      sendEvent({ ...payload, event: event as TrackingEvent, eventCallback });
    },
    [],
  );

  return (
    <AppContext.Provider value={contexts.App}>
      <RouteContextProvider>
        <PageLayoutContextProvider pageLayoutConfig={contexts.PageLayoutConfig}>
          <FeatureFlagStoreProvider initialFlags={contexts.FeatureFlags}>
            <UserPreferencesContextProvider cookies={cookies}>
              <PricingContextProvider>
                <DesignSystemProvider
                  HelmetComponent={Helmet as any}
                  sendEvent={sendEventDs}
                  Link={Link}
                  historyPush={history.push}
                  publicPath={`${contexts.App.site.assetDomain}/design-system/`}
                  useHref={(to) =>
                    to?.startsWith('/') ? `${contexts.App.site.pathPrefix}${to}` : to
                  }
                  translationNamespace="lvhds"
                >
                  <ThemeProvider theme={contexts.Theme}>
                    <GlobalStyles>{children}</GlobalStyles>
                  </ThemeProvider>
                </DesignSystemProvider>
              </PricingContextProvider>
            </UserPreferencesContextProvider>
          </FeatureFlagStoreProvider>
        </PageLayoutContextProvider>
      </RouteContextProvider>
    </AppContext.Provider>
  );
};
